export const environment = {
  production: true,
  domain: 'app.hellobod.io',
  api: 'https://bod-api-ubkdyoueiq-nw.a.run.app',
  firebase: {
    apiKey: 'AIzaSyClcy4hRTDzU0bTrrWuFPKaUJuvTy6jVsE',
    authDomain: 'app.hellobod.io',
    projectId: 'bod-app-prod',
    storageBucket: 'bod-app-prod.appspot.com',
    messagingSenderId: '541677765314',
    appId: '1:541677765314:web:23ecb4566ad8bfc5b2715b',
    measurementId: 'G-L2JZV1VQWX',
  },
};
